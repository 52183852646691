import React from 'react'
import Layout from '../components/layout'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import SEO from '../components/seo'
import BlogList from '../components/blogList'
import { Section, Wrapper } from '../components/baseStyles'
import Slika from '../images/header/cesta_pitanja.svg'

const ExtendedSection = styled(Section)`
  width: calc((100% / 12) * 10);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  @media screen and (max-width: 1280px) {
    width: calc((100% / 12) * 10);
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    padding: 0;
  }
`

const ExtendedWrapper = styled(Wrapper)`
  position: relative;
  top: -75px;
  z-index: 2;
`

const Blog = ({ location, pageContext: { locale }, data }) => {
  const isBlogPage = true
  return (
    <Layout
      isBlogPage={isBlogPage}
      locale={locale}
      heroImage={Slika}
      title="Novosti iz turizma i vodiči za iznajmljivače"
      text="Na jednom mjestu donosimo sve najvažnije informacije vezane za Litto i turizam. "
    >
      <SEO
        title="Novosti iz turizma i vodiči za iznajmljivače"
        description="Kako dostići superhost status na Airbnb? Funkcionira li Google My Business kao alat za oglašavanje? Koji su posljednji trendovi u turizmu?"
        keywords={['Litto']}
        lang={locale}
        isBlogPage={isBlogPage}
        blogListData={data}
      />

      <ExtendedWrapper>
        <ExtendedSection>
          <BlogList />
        </ExtendedSection>
      </ExtendedWrapper>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            slug
            title
            seoMetaDesc
            date
          }
          html
        }
      }
    }
  }
`

export default Blog
